<template>
  <v-layout justify-center align-center>
    <v-row dense v-if="items">
      <v-col cols="12">
        <v-card class="mb-4 primary darken-4" dark>
          <v-card-title>
            <v-btn icon @click="$router.push({ name: 'home' })">
              <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <span>
              {{ $lang("learn") }}
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-list v-if="items" class="primary darken-2" dark>
            <v-list-item v-if="items && items.length">
              <v-list-item-title>
                {{ $lang("Total") + " " + $lang("Tags") }}
              </v-list-item-title>
              <v-list-item-action>
                {{ pagination.total ? pagination.total : items.length }}
              </v-list-item-action>
            </v-list-item>
            <v-card-actions v-else>
              <v-spacer></v-spacer>
              {{ loading ? $lang("Loading") : $lang("No items") }}
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-list>
        </v-card>
      </v-col>
      <v-col v-for="(item, i) in items" :key="i" cols="6">
        <v-card
          :style="'background-color: ' + colorLuminance(getRandomColor(), -0.7)"
          @click="
            $router.push({
              name: 'tags-questions',
              params: { id: item.id },
              query: { parent_page: $route.query.page },
            })
          "
          dark
        >
          <v-card-text style="height: 100px; overflow: hidden">
            <div class="caption">
              {{ ` ${$lang("total")} ${$lang("questions")} ` }}:
              {{ item.__meta__.total_questions }}
            </div>
            <div class="title white--text">
              {{
                $store.getters.getCurrLocale == "en"
                  ? item.title
                  : item.title_hi
                  ? item.title_hi
                  : item.title
              }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <query-page-redirect :pagination="pagination" />
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import QueryPageRedirect from "@/components/QueryPageRedirect";
import { Device } from "@capacitor/device";
import { interstitial } from "@/plugins/admob/interstitial";

export default {
  name: "tags",
  components: { QueryPageRedirect },
  data() {
    return {
      items: [],
      loading: true,
      pagination: {
        lastPage: null,
        page: 1,
        perPage: null,
        total: null,
      },
      info: null,
      isAdShown: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getRandomColor1() {
      // var r = () => (Math.random() * 256) >> 0;
      // var color = `rgb(${r()}, ${r()}, ${r()})`;
      // return color;
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    colorLuminance(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },
    fetchItems(page = 1) {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("prep/tags/public?page=" + page)
        .then((res) => {
          this.items = res.data.data;
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;

          bus.$emit("hideWait");
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        });
    },
  },
  async created() {
    this.fetchItems(this.$route.query.page);
    this.info = await Device.getInfo();
    if (!this.isAdShown) {
      if (this.info && this.info.platform != "web") {
        await interstitial();
        this.isAdShown = true;
      }
    }
  },
};
</script>

